import { Injectable } from "@angular/core";
import { MainTbPrayerBox } from "../classes/main-tb-prayer-box";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MainTbPrayerBoxService {
  headersToken = new HttpHeaders();

  private baseUrl = environment.baseUrl;
  // private  baseUrl = "http://localhost:8080/FileUpload/api/";

  constructor(private http: HttpClient) {
    if (localStorage.getItem("token") && localStorage.getItem("userDetail")) {
      let headers = new HttpHeaders()
        .set("content-type", "application/json")
        .set("Access-Control-Allow-Origin", "*")
        .set("Authorization", JSON.stringify(localStorage.getItem("token")))
        .set(
          "userId",
          String(JSON.parse(localStorage.getItem("userDetail")).id)
        );
      this.headersToken = headers;
    }
  }
  getData(page: number) {
    const url = `${this.baseUrl}v1/datahistory?page=${page}`;
    return this.http.get<MainTbPrayerBox[]>(url, {
      headers: this.headersToken,
    });
  }
  getMainTotalRecords(): Observable<number> {
    const url = this.baseUrl + "v1/get-main-total-records";
    return this.http.get<number>(url, { headers: this.headersToken });
  }
  updateMessage(id: number, message: string) {
    const url = this.baseUrl + "v1/update-message";
    return this.http.post(url, { id, message }, { headers: this.headersToken });
  }
}
