export class TbPrayerBox {
  message: string;
  profileImage: String;
  filename: String;
  msisdn: String;
  createTS: Date;
  celbcode: String;
  scheduleDate: Date;
  status: number;
  conType: String;
  id: number;
}
