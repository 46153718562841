<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header-danger">
          <h4 class="card-title">Celeb List</h4>
        </div>
        <div
          class="card-body"
          [class.spinner]="loading"
          [attr.disabled]="loading"
        >
          <div class="table-responsive">
            <table class="table">
              <thead class="text-danger">
                <th>#ID</th>
                <th>CelebName</th>
                <th>ScheduleDate</th>
                <th>Message</th>
                <th>FilePath</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of result; let i = index">
                  <th>{{ item.id }}</th>
                  <td>{{ item.msisdn }}</td>
                  <td>{{ item.createTS | date : "yyyy-MM-dd" }}</td>
                  <td>{{ item.message }}</td>
                  <td>{{ item.filename }}</td>
                  <td>
                    <button
                      class="btn btn-primary"
                      mat-fab
                      (click)="openModal(callAPIDialog, item.message, item.id)"
                    >
                      EDIT
                    </button>
                    <!-- <button
                      type="button"
                      class="btn btn-success"
                      (click)="approve(item, i)"
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      class="btn btn-default"
                      (click)="reject(item, i)"
                    >
                      Reject
                    </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="pagination"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
            "
          >
            <button (click)="prevPage()" [disabled]="currentPage === 1">
              &lt;&lt;
            </button>
            <ng-container *ngFor="let pageNum of getPageNumbers()">
              <button
                (click)="goToPage(pageNum)"
                [disabled]="pageNum === currentPage"
              >
                {{ pageNum }}
              </button>
            </ng-container>
            <button
              (click)="nextPage()"
              [disabled]="currentPage === totalPages()"
            >
              &gt;&gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #callAPIDialog>
    <div *ngIf="isopen" id="modal-content-wrapper">
      <section id="modal-body">
        <h3>Edit Message</h3>
        <div style="display: flex; align-items: center">
          <label for="editedMessage" style="margin-right: 5px">Message:</label>
          <textarea
            id="editedMessage"
            [(ngModel)]="editedMessage"
            style="width: 100%; height: 200px; padding: 5px"
          ></textarea>
        </div>
        <div
          style="
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <button
            class="btn btn-primary"
            mat-button
            color="primary"
            (click)="onSaveClick()"
          >
            Save
          </button>
          <button
            class="btn btn-primary"
            style="margin-left: 15px"
            mat-button
            (click)="onNoClick()"
          >
            Cancel
          </button>
        </div>
      </section>
    </div>
  </ng-template>
</div>
