<ng2-slim-loading-bar color="blue"></ng2-slim-loading-bar>
<header *ngIf="authServ.getLoginStatus()">
  <div class="container">
    <a routerLink="/home" class="logo"
      ><img src="assets/img/bubbly_logo.png" alt="" width="3%" height="auto"
    /></a>
    <nav>
      <ul>
        <li>
          <h3><a href="#" routerLink="/home">Home</a></h3>
        </li>
        <li>
          <h3><a href="#" routerLink="/list">CelebList</a></h3>
        </li>
        <li>
          <h3><a href="#" routerLink="/taushiyalist">TaushiyaList</a></h3>
        </li>
        <li>
          <h3><a href="#" routerLink="/upload">Upload Content</a></h3>
        </li>

        <li>
          <h3><a (click)="logout()">Logout </a></h3>
        </li>
      </ul>
    </nav>
  </div>
</header>

<nav class="navbar navbar-expand-sm bg-light">
  <div class="bg">
    <div class="container">
      <!-- <img src="assets/img/bg_image.png">-->
      <h1>Welcome to Content Upload</h1>
      <router-outlet></router-outlet>
    </div>
  </div>
</nav>
<!-- Modal -->
<!-- <div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">


    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Modal Header</h4>
      </div>
      <div class="modal-body">
        <iframe class="snake-project" src="http://localhost:8080/games/GalacticWar/index.html"></iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)='close()'>Close</button>
      </div>
    </div>

  </div>
</div> -->

<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
        <h4 class="modal-title">Logout Confirmation</h4>
      </div>
      <div class="modal-body">
        <p>Are You Sure You want to logout</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="logoutSubmit()"
        >
          Logout
        </button>
      </div>
    </div>
  </div>
</div>
