import { Component, OnInit } from "@angular/core";
import { TemplateRef, ViewChild } from "@angular/core";
import { TbPrayerBox } from "../classes/tb-prayer-box";
import { TbPrayerBoxService } from "../services/tb-prayer-box.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-taushiya-list",
  templateUrl: "./taushiya-list.component.html",
  styleUrls: ["./taushiya-list.component.css"],
})
export class TaushiyaListComponent implements OnInit {
  private baseUrl = environment.baseUrl;
  private filePath = environment.filePath;
  private filePath1 = environment.filePath1;
  checklistMaster = false;
  selectedCheckboxes: { [key: string]: boolean } = {};
  isopen: boolean;
  private tbPrayerBox = new TbPrayerBox();
  loading = true;
  result: TbPrayerBox[];
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  constructor(
    private tbPrayerBoxService: TbPrayerBoxService,
    public matDialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.isopen = false;
  }

  ngOnInit() {
    this.getContentData(this.currentPage);
  }

  getContentData(page: number): void {
    this.loading = true;
    this.tbPrayerBoxService.getTauData(page).subscribe((data: any) => {
      this.result = data.tbPrayerBoxs;
      this.tbPrayerBoxService
        .getTotalRecords()
        .subscribe((totalRecords: number) => {
          this.totalRecords = totalRecords;
          this.loading = false;
        });
      this.loading = false;
    });
  }

  modalDialog: any;
  profileImage: string;
  filename: String;
  openModal(callAPIDialog, profileImage, filename) {
    this.isopen = true;
    this.profileImage = profileImage;
    this.filename = this.baseUrl + filename.replace(this.filePath, "");
    console.log("Profile: ", this.filename);
    const dialogConfig = new MatDialogConfig();
    console.log("enter in open dialog");
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "220px";
    dialogConfig.width = "360px";
    // https://material.angular.io/components/dialog/overview
    this.modalDialog = this.matDialog.open(callAPIDialog, dialogConfig);
  }

  onNoClick(): void {
    this.modalDialog.close();
  }
  approve(item, i) {
    this.loading = true;
    this.tbPrayerBox.status = item.status;
    this.tbPrayerBox.msisdn = item.msisdn;
    this.tbPrayerBox.celbcode = item.celbcode;
    this.tbPrayerBox.scheduleDate = item.scheduleDate;
    this.tbPrayerBox.createTS = item.createTS;
    this.tbPrayerBox.message = item.message;
    this.tbPrayerBox.id = item.id;
    this.tbPrayerBox.profileImage = item.profileImage;
    this.tbPrayerBox.filename = item.filename;

    // const formdata = new FormData();
    let formdata = {
      id: item.id,
    };
    console.log(formdata);
    this.tbPrayerBoxService.approveTau(item.id).subscribe((data: any) => {
      let res = data;
      if (data.statuscode == 200) {
        this.toastr.success("Content approval is done");
        this.result.splice(i, 1);
        // alert();
        this.loading = false;
        this.getContentData(1);
      }
    });
  }
  reject(item, i) {
    this.loading = true;
    this.tbPrayerBox.status = item.status;
    this.tbPrayerBox.msisdn = item.msisdn;
    this.tbPrayerBox.celbcode = item.celbcode;
    this.tbPrayerBox.scheduleDate = item.scheduleDate;
    this.tbPrayerBox.createTS = item.createTS;
    this.tbPrayerBox.message = item.message;
    this.tbPrayerBox.id = item.id;
    this.tbPrayerBox.profileImage = item.profileImage;
    this.tbPrayerBox.filename = item.filename;

    this.tbPrayerBoxService.reject(item.id).subscribe((data: any) => {
      let res = data;

      if (data.statuscode == 200) {
        this.toastr.success("Content rejection is done");
        this.result.splice(i, 1);
        // alert();
        this.loading = false;
        this.getContentData(1);
      }
      // this.result.splice(i, 1);
      // alert("Content rejection is done");
      // this.loading = false;
    });
  }
  // toggleAllCheckboxes() {
  //   console.log("Toggling all checkboxes");
  //   for (const item of this.result) {
  //     this.selectedCheckboxes[item.id] = this.checklistMaster;
  //   }
  //   console.log(this.selectedCheckboxes);
  // }

  toggleAllCheckboxes() {
    console.log("Toggling all checkboxes");
    this.selectedCheckboxes = {};
    for (const item of this.result) {
      this.selectedCheckboxes[item.id] = this.checklistMaster;
    }
    console.log(this.selectedCheckboxes);
  }

  // toggleCheckbox(id: string) {
  //   console.log(`Toggling checkbox for ID: ${id}`);
  //   const allChecked = Object.values(this.selectedCheckboxes).every(
  //     (value) => value
  //   );
  //   this.checklistMaster = allChecked;
  //   console.log(this.selectedCheckboxes);
  // }

  toggleCheckbox(id: string) {
    console.log(`Toggling checkbox for ID: ${id}`);
    this.selectedCheckboxes[id] = !this.selectedCheckboxes[id];
    const allChecked = Object.values(this.selectedCheckboxes).every(
      (value) => value
    );
    this.checklistMaster = allChecked;
    console.log(this.selectedCheckboxes);
  }

  bulkApprove() {
    const selectedItems = this.getSelectedItems();
    this.loading = true;
    this.tbPrayerBoxService
      .bulkApprove(selectedItems)
      .subscribe((data: any) => {
        let res = data;
        if (data.statuscode == 200) {
          this.toastr.success("Content approval is done");
          this.loading = false;
          this.getContentData(1);
        }
      });
  }

  bulkReject() {
    const selectedItems = this.getSelectedItems();
    this.loading = true;
    this.tbPrayerBoxService.bulkReject(selectedItems).subscribe((data: any) => {
      let res = data;
      if (data.statuscode == 200) {
        this.toastr.success("Content rejection is done");
        this.loading = false;
        this.getContentData(1);
      }
    });
  }

  areCheckboxesSelected(): boolean {
    return Object.values(this.selectedCheckboxes).some((value) => value);
  }

  getSelectedItems(): TbPrayerBox[] {
    return this.result.filter((item) => this.selectedCheckboxes[item.id]);
  }
  getPageNumbers(): number[] {
    const totalPages = this.totalPages();
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  goToPage(page: number): void {
    this.currentPage = page;
    this.getContentData(page);
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getContentData(this.currentPage);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages()) {
      this.currentPage++;
      this.getContentData(this.currentPage);
    }
  }
  totalPages(): number {
    return Math.ceil(this.totalRecords / this.pageSize);
  }
  showTooltip(action: string): string {
    if (!this.areCheckboxesSelected()) {
      if (action === "approve") {
        return "Please toggle checkbox to use bulk approve button";
      } else if (action === "reject") {
        return "Please toggle checkbox to use bulk reject button";
      }
    }
    return "";
  }

  generateAudioSource(filename: string): string {
    return (
      this.baseUrl +
      filename.replace(this.filePath, "").replace(this.filePath1, "")
    );
  }
  getModifiedFilename(filename: string): string {
    return filename
      .replace("D:/content_upload/data/", "")
      .replace("/home/testingcontent/test_content_upload/data", "");
  }
}
