import { Component, OnInit } from '@angular/core';  
import { FormGroup, FormControl, Validators ,FormBuilder } from '@angular/forms';  
import { UserDetail } from '../classes/user-detail';  
import { UserDetailService } from '../services/user-detail.service';
import { CelebrityService }  from '../services/celebrity.service';
import { Celebrity } from '../classes/celebrity';
import { TbPrayerBox } from '../classes/tb-prayer-box';
import { TbPrayerBoxService } from '../services/tb-prayer-box.service';
@Component({
  selector: 'app-taushiya-blog',
  templateUrl: './taushiya-blog.component.html',
  styleUrls: ['./taushiya-blog.component.css']
})
export class TaushiyaBlogComponent implements OnInit {

  selectedFiles: FileList;  
  currentFileUpload: File;  
  registerForm: FormGroup;
  loading = false;

  submitted = false;
  
  statusarr: any = ['Approve', 'Testing']
  ;
  private tbPrayerBox = new TbPrayerBox();  
  private celebrity = new Celebrity ();
  result:Celebrity[];
  cellist :string[];

    
  constructor(private tbPrayerBoxService : TbPrayerBoxService,private formBuilder: FormBuilder,private celebrityService : CelebrityService) {
  
      this.registerForm = this.formBuilder.group({  
    message : new FormControl('' , Validators.required),  
    status : new FormControl('' , Validators.required),
    celbcode : new FormControl('' , Validators.required),
    scheduledate : new FormControl('' , Validators.required),
    profileImage : new FormControl()  
  });   

  
 
  
}  
  
  ngOnInit() { 
   
    console.log("enter in tau content insert..........");
    this.celebrityService.getData().subscribe((data: any) => {
      this.result = data.celebritylist;
     // console.log("enter in tau content insert..........");
      console.log(this.result);
    });

    
   
  }  
  
  selectFile(event) {  
    const file = event.target.files.item(0);  
    if(file ==undefined)
    {
      alert('invalid format!');  
    }
   
    if (file.type.match('audio.*')) {  
      var size = event.target.files[0].size;  
      var kbsize=(size)/1024;
      if(kbsize > 204800)  
      {  
          alert("size must not exceeds 200 MB");  
          this.registerForm.get('profileImage').setValue("");  
      }  
      else  
      {  
        this.selectedFiles = event.target.files; 

      }  
    } else {  
      alert('invalid format!');  
      this.registerForm.get('profileImage').setValue("");  
    }  
  
  }     
  
  // create the form object.  
  
  
  AdminForm(AdminInformation)  
  {  
    this.loading = true;
    console.log("enter in admin form");
    if(this.selectedFiles == null){
      alert('Select file first');  
      this.loading = false;
      return;
    }
    console.log("enter in admin 111form");
    this.submitted = true;
    console.log("enter in admin 2222111form");
    if (this.registerForm.invalid) {
      console.log("enter in admin form0000000000000");
      this.loading = false;
      return;
  }
  console.log("enter in admin form..."+this.scheduledate.value);
    this.tbPrayerBox.message = this.message.value;  
    this.tbPrayerBox.status = this.status.value;  
    this.tbPrayerBox.celbcode=this.celbcode.value;
    this.tbPrayerBox.scheduleDate=this.scheduledate.value;
    this.tbPrayerBox.conType= 'taushiya';
    this.tbPrayerBox.profileImage=this.registerForm.get('profileImage').value;
    
  
    console.log(this.tbPrayerBox);  
  
    this.tbPrayerBoxService.saveData(this.tbPrayerBox).subscribe((data: any) => {
      let result = data;
      console.log(result.statusDescription.statusCode);
  //let result = response.json();  
         // console.log(result);  
          if(result.statusDescription.statusCode > 0 )  
          {  
            if(this.selectedFiles != null)  
            {  
              this.currentFileUpload = this.selectedFiles.item(0);  
              console.log(this.currentFileUpload);  
  
              this.tbPrayerBoxService.uploadFile(this.currentFileUpload , result.tbBox.id).subscribe((data: string[]) => {
                let re = data;
                console.log(re[0]);
                if( this.tbPrayerBox.status ==0)
                {
                  this.tbPrayerBox.id =result;
                  this.tbPrayerBox.profileImage=re[0];
                  this.tbPrayerBoxService.approveTau(this.tbPrayerBox).subscribe((data: number) => {
                    let res = data;
                  
                   // this.result.splice(i,1);
                    //alert("Content approval is done");
                   
                  });
                } 
                   // let re = res.json();  
                     if(re[0] != "-5")  
                     {  
                        alert("file upload successfully ");  
                        this.loading = false;
                        this.submitted = false;
                        this.registerForm.reset();
                     }  
                     else{  
                        alert("error while uploading fie details");  
                        this.loading = false;
                     }  

                    
                  },  
                  err => {  
                      alert("error while uploading fie details");  
                      this.loading = false;
                  }  
              );
              
         
  
            }  
          }  
      },  
      error => {  
        console.log("error while saving data in the DB");  
        this.loading = false;
      }  
    ); 
   
  
  }  
  
    get message(){  
      return this.registerForm.get('message');  
    } 
  
    
    get celbcode(){  
      return this.registerForm.get('celbcode');  
  }  
  get scheduledate(){  
    return this.registerForm.get('scheduledate');  
}
get status(){  
  return this.registerForm.get('status');  
}
get f() { return this.registerForm.controls; }
       
}  