import { Component } from "@angular/core";
import { SlimLoadingBarService } from "ng2-slim-loading-bar";

import {
  NavigationCancel,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { LoginserviceService } from "./services/loginservice.service";
import { UserDetailService } from "./services/user-detail.service";
import { ToastrService } from "ngx-toastr";
declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "dataupload";
  constructor(
    private loadingBar: SlimLoadingBarService,
    private router: Router,
    public authServ: LoginserviceService,
    private userserv: UserDetailService,
    private toastr: ToastrService
  ) {
    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loadingBar.start();
    }
    if (event instanceof NavigationEnd) {
      this.loadingBar.complete();
    }
    if (event instanceof NavigationCancel) {
      this.loadingBar.stop();
    }
    if (event instanceof NavigationError) {
      this.loadingBar.stop();
    }
  }
  open() {
    $("#view_images").modal("show");
  }
  close() {
    $("iframe").attr("src", $("iframe").attr("src"));
    $("#myModal").modal("hide");
  }

  logout() {
    $("#myModal").modal("show");
  }
  logoutSubmit() {
    let userId = JSON.parse(localStorage.getItem("userDetail")).id;

    this.userserv.logout(userId).subscribe((data: any) => {
      if (data) {
        if (data.statusDescription.statuscode == 200) {
          this.toastr.success("Logout Successfully");
          this.removeDataandRedirect();
        }
      }
    });
  }

  removeDataandRedirect() {
    this.router.navigate([""]);
    this.authServ.checkLoginStatus();

    localStorage.clear();
    this.authServ.logout();
  }
}
