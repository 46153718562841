import { JsonPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {
  isLogin: boolean = false;

  constructor() {
    this.checkLoginStatus()
  }


  onlogin() {
    this.isLogin = true
  }

  getLoginStatus() {
    return this.isLogin;

  }
  logout() {
    this.isLogin = false;

  }




  checkLoginStatus() {
    let UserDetail = JSON.parse(localStorage.getItem('userDetail'));
    let token = localStorage.getItem('token')
    if (UserDetail && token) {
      this.isLogin = true;
    }
    else {
      this.isLogin = false;
    }
  }




}
