import { Injectable } from "@angular/core";
import { Celebrity } from "../classes/Celebrity";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CelebrityService {
  private baseUrl = environment.baseUrl;
  headersToken = new HttpHeaders();
  //private  baseUrl = "http://192.168.1.88:8081/FileUpload/api/getCelbList/";
  //private  baseUrl = "http://localhost:8080/FileUpload/api/getCelbList/";
  res: Celebrity[];
  constructor(private http: HttpClient) {
    if (localStorage.getItem("token") && localStorage.getItem("userDetail")) {
      let headers = new HttpHeaders()
        .set("content-type", "application/json")
        .set("Access-Control-Allow-Origin", "*")
        .set("Authorization", JSON.stringify(localStorage.getItem("token")))
        .set(
          "userId",
          String(JSON.parse(localStorage.getItem("userDetail")).id)
        );
      this.headersToken = headers;
    }
  }

  getData() {
    let url = this.baseUrl + "celebrity/v1/list";
    //return this.http.get<Celebrity[]>(url);

    return this.http.get<Celebrity[]>(url);
  }

  uploadZipandxls(form: any) {
    return this.http.post(`${this.baseUrl}v1/tbPrayerBox/upload`, form);
  }
}
