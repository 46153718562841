import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RegisterComponent } from "./register/register.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatDatepickerModule,
  MatInputModule,
  MatNativeDateModule,
} from "@angular/material";
import { TablelistComponent } from "./tablelist/tablelist.component";
import { HomeComponent } from "./home/home.component";
import { TaushiyaBlogComponent } from "./taushiya-blog/taushiya-blog.component";
import { TaushiyaListComponent } from "./taushiya-list/taushiya-list.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { SlimLoadingBarModule } from "ng2-slim-loading-bar";
import { ComponentNameComponent } from "./component-name/component-name.component";
import { BrowserModuleComponent } from "./browser-module/browser-module.component";
import { SlimLoadingBarModuleComponent } from "./slim-loading-bar-module/slim-loading-bar-module.component";
import { LoginComponent } from "./user/login/login.component";
import { ToastrModule } from "ngx-toastr";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CommonModule } from "@angular/common";
// import  {MatDatepicker}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    TablelistComponent,
    HomeComponent,
    TaushiyaBlogComponent,
    TaushiyaListComponent,
    ComponentNameComponent,
    BrowserModuleComponent,
    SlimLoadingBarModuleComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    SlimLoadingBarModule,
    FormsModule,
    CommonModule,
    MatTooltipModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 3000,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
