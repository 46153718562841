<div class="login">
  <h1 class="text-center">Data Upload Login</h1>
  <form
    class="needs-validation"
    #loginForm="ngForm"
    (ngSubmit)="loginSubmit(loginForm)"
  >
    <div class="form-group was-validated">
      <label class="form-label" for="Username">Username</label>
      <input
        class="form-control"
        name="userName"
        [ngClass]="{ 'is-invalid': loginForm.submitted && userName.invalid }"
        type="text"
        placeholder="Enter Username"
        #userName="ngModel"
        ngModel
        id="Username"
        required
      />
      <span class="text-danger" *ngIf="loginForm.submitted && userName.invalid">
        UserName is required
      </span>
    </div>
    <div class="form-group was-validated">
      <label class="form-label" for="Password">Password</label>
      <input
        class="form-control"
        name="password"
        type="Password"
        [ngClass]="{ 'is-invalid': loginForm.submitted && password.invalid }"
        placeholder="Enter Password"
        #password="ngModel"
        ngModel
        id="password"
        required
      />
      <span class="text-danger" *ngIf="loginForm.submitted && password.invalid">
        Password is required
      </span>
    </div>

    <div class="form-group form-click">
      <input class="form-click-input" type="checkbox" id="check" />
      <label class="form-click-label" style="margin-left: 10px" for="check"
        >Remember me</label
      >
    </div>

    <input class="btn btn-success w-100" type="submit" value="Sign in" />
  </form>
</div>
