<div class="container">
  <div id="content">
    <div
      id="search"
      style="margin: 5px; background-color: #f26d21; border-radius: 30px"
      class="first"
      onmouseout="this.style.backgroundColor='#f26d21'"
      onmouseover="this.style.backgroundColor='white'"
    >
      <div class="f1">
        <a href="#" routerLink="/upload"><h3>Celeb Blog</h3></a>
      </div>
    </div>
    <div
      id="results"
      style="margin: 5px; background-color: #f26d21; border-radius: 30px"
      class="second"
      onmouseout="this.style.backgroundColor='#f26d21'"
      onmouseover="this.style.backgroundColor='white'"
    >
      <div class="f2">
        <a href="#" routerLink="/taushiya"><h3>Taushiya Blog</h3></a>
      </div>
    </div>
  </div>
</div>
