export class LoginDetail {
    id: number | any;
    countryId: number | any;
    email: string;
    emailVerification: boolean;
    enabled: boolean;
    createdDate: string | any;
    firstName: string | any;
    lastName: string | any;
    mobileNo: string | any;
    username: string | any;
    firstLogin: boolean;
    accountType: string | any;
    userTokenDetailsList: UserTokenDetailList[] | any[];
    "countryName": string | any;
    "passwd": string | any;
}




export class UserTokenDetailList {
    jwtToken: string | any
    jwtRefreshToken: string | any
    jwtTokenExpiryDateTime: string | any;
    jwtRefreshTokenExpiryDateTime: string | any;
    source: string | any
}




export class UserDetails {
    loginDetail: LoginDetail;

}



export class Response {
    statuscode: string | any;
    statusdescription: string | any
}