<form [formGroup]="registerForm" *ngIf="registerForm" #AdminInformation (ngSubmit)="AdminForm(AdminInformation)">
  <div class="container">
    <h2 style="text-align: center;">Taushiya Blog</h2>
    <div class="row">
      <div class="form-group col-10">
        <label for="celbcode"> Name </label>
        <select formControlName="celbcode" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.celbcode.errors }">
          <!-- <option disabled>Select celb Name</option>-->
          <option value="">Select celb Name</option>
          <option *ngFor="let suit of result" value={{suit.celebcode}}>{{suit.celebname}}</option>
        </select>
        <div *ngIf="submitted && f.celbcode.errors" class="invalid-feedback">
          <div *ngIf="f.celbcode.errors.required">CelbName is required</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-5">
        <label for="scheduledate"> Schedule Date </label>
        <input formControlName="scheduledate" class="form-control" type="date"
          [ngClass]="{ 'is-invalid': submitted && f.scheduledate.errors }">
        <div *ngIf="submitted && f.scheduledate.errors" class="invalid-feedback">
          <div *ngIf="f.scheduledate.errors.required">ScheduleDate is required</div>
        </div>
      </div>
    </div>
    <!-- <div class="row">  
      <div class="form-group col-5">  
          <label for="message"> Message </label>  
          <textarea formControlName="message" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
          <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
            <div *ngIf="f.message.errors.required">Message is required</div>
        </div>
      </div>  
    </div>  -->

    <div class="row">
      <div class="form-group col-5">
        <label for="message"> Message </label>
        <textarea formControlName="message" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
          <div *ngIf="f.message.errors.required">Message is required</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-5">

        <label for="profileImage">Upload File</label>
        <input formControlName="profileImage" class="form-control" type="file" (change)="selectFile($event)"
          [ngClass]="{ 'is-invalid': submitted && f.profileImage.errors }">
        <div *ngIf="submitted && f.profileImage.errors" class="invalid-feedback">
          <div *ngIf="f.profileImage.errors.required">File is required</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-5">
        <label for="status"> Status </label>
        <select formControlName="status" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
          <!--<option disabled>Choose Status</option>-->
          <option disabled value="">Choose Status</option>
          <option value="0">Approve</option>
          <option value="1">Testing</option>
          <!-- <option *ngFor="let suit of statusarr">{{suit}}</option>-->
        </select>
        <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
          <div *ngIf="f.status.errors.required">Status is required</div>
        </div>
      </div>
    </div>
    <div class="text-center">

      <button class="btn btn-md btn-primary btn-style" mat-raised-button color="primary" [class.spinner]="loading"
        [disabled]="loading">Save</button>

    </div>
  </div>
</form>