// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "http://bubbly-desktop-api.bubbly.net/dataupload/",
  baseUrl: "http://bubbly-desktop-api.bubbly.net/dataupload/",
  // url: "http://localhost:7600/",
  // baseUrl: "http://localhost:7600/",
  //baseUrl : "http://localhost:8080/FileUpload/api/",
  gameUrl: "http://localhost:8080/games/",
  filePath: "D:/content_upload/data/",
  filePath1: "/home/monthly_content_upload/data/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
