<form
  #registerForm="ngForm"
  *ngIf="registerForm"
  #AdminInformation
  (ngSubmit)="uploadSubmit(AdminInformation)"
  enctype="multipart/form-data"
>
  <div class="container">
    <h2 style="text-align: center">Celeb Blog</h2>
    <div class="row">
      <div class="form-group col-10">
        <label for="celbcode"> Name </label>
        <select
          name="celbcode"
          #celebcode="ngModel"
          [(ngModel)]="celebCode"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && celebcode.invalid }"
        >
          <!-- <option disabled>Select celb Name</option>-->
          <option value="">Select celb Name</option>
          <option *ngFor="let suit of result" value="{{ suit.celebcode }}">
            {{ suit.celebname }}
          </option>
        </select>
        <div *ngIf="submitted && celebcode.invalid" class="invalid-feedback">
          <div *ngIf="submitted && celebcode.invalid">CelbName is required</div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="form-group col-5">
        <label for="xlsxFile">Upload Excel File</label>
        <input
          name="xlsxFile"
          #xlsxfile="ngModel"
          ngModel
          class="form-control"
          type="file"
          (change)="selectFile($event)"
          [ngClass]="{ 'is-invalid': submitted && xlsxFile.invalid }"
        />
        <div *ngIf="submitted && xlsxFile.invalid" class="invalid-feedback">
          <div *ngIf="xlsxFile.invalid">File is required</div>
        </div>
      </div> -->
      <div class="form-group col-5">
        <label for="xlsxFile">Upload Excel File</label>
        <input
          name="xlsxFile"
          #xlsxfile="ngModel"
          [(ngModel)]="xlsxFileValue"
          class="form-control"
          type="file"
          (change)="selectFile($event)"
          [ngClass]="{ 'is-invalid': submitted && xlsxfile.invalid }"
        />
        <div *ngIf="submitted && xlsxfile.invalid" class="invalid-feedback">
          File is required
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="form-group col-5">
        <label for="zip">Upload Zip File</label>
        <input
          name="zipFile"
          #zipFile="ngModel"
          ngModel
          class="form-control"
          type="file"
          (change)="selectZipFile($event)"
          [ngClass]="{ 'is-invalid': submitted && zipFile.invalid }"
        />
        <div *ngIf="submitted && f.zipFile.errors" class="invalid-feedback">
          <div *ngIf="f.zipFile.errors.required">File is required</div>
        </div>
      </div> -->
      <div class="form-group col-5">
        <label for="zip">Upload Zip File</label>
        <input
          name="zipFile"
          #zipfile="ngModel"
          [(ngModel)]="zipFileValue"
          class="form-control"
          type="file"
          (change)="selectZipFile($event)"
          [ngClass]="{ 'is-invalid': submitted && zipfile.invalid }"
        />
        <div *ngIf="submitted && zipfile.invalid" class="invalid-feedback">
          File is required
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="form-group col-5">
        <label for="status"> Status </label>
        <select formControlName="status" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.status.errors }">

          <option disabled selected value="">Choose Status</option>
          <option value="0">Approve</option>
          <option value="1">Testing</option>
        </select>
        <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
          <div *ngIf="f.status.errors.required">Status is required</div>
        </div>
      </div>
    </div> -->
    <div
      class="text-center"
      style="display: flex; align-items: center; justify-content: center"
    >
      <button
        class="btn btn-md btn-primary btn-style"
        style="margin-right: 10px"
        type="submit"
        mat-raised-button
        color="primary"
        [class.spinner]="loading"
        [disabled]="loading"
      >
        Save
      </button>
      <button
        class="btn btn-md btn-secondary btn-style"
        type="reset"
        mat-raised-button
        color="secondary"
        [disabled]="loading"
      >
        Reset
      </button>
    </div>
  </div>
</form>
