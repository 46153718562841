import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { TablelistComponent } from './tablelist/tablelist.component';
import { HomeComponent } from './home/home.component';
import { TaushiyaBlogComponent } from './taushiya-blog/taushiya-blog.component';
import { TaushiyaListComponent } from './taushiya-list/taushiya-list.component';
import { LoginComponent } from './user/login/login.component';
import { AuthGuard } from './utils/auth.guard';



const routes: Routes = [

  { path: 'upload', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'list', component: TablelistComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: "", component: LoginComponent },
  { path: 'taushiya', component: TaushiyaBlogComponent, canActivate: [AuthGuard] },
  { path: 'taushiyalist', component: TaushiyaListComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
