import { Injectable } from "@angular/core";
import { TbPrayerBox } from "../classes/tb-prayer-box";
import { MainTbPrayerBox } from "../classes/main-tb-prayer-box";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  HttpClient,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class TbPrayerBoxService {
  headersToken = new HttpHeaders();

  private baseUrl = environment.baseUrl;
  // private  baseUrl = "http://localhost:8080/FileUpload/api/";

  constructor(private http: HttpClient) {
    if (localStorage.getItem("token") && localStorage.getItem("userDetail")) {
      let headers = new HttpHeaders()
        .set("content-type", "application/json")
        .set("Access-Control-Allow-Origin", "*")
        .set("Authorization", JSON.stringify(localStorage.getItem("token")))
        .set(
          "userId",
          String(JSON.parse(localStorage.getItem("userDetail")).id)
        );
      this.headersToken = headers;
    }
  }

  saveData(tbPrayerBox: TbPrayerBox): Observable<any> {
    console.log("enter in save form");
    let url = this.baseUrl + "tbPrayerBox/v1/save";
    return this.http.post(url, tbPrayerBox);
  }

  uploadFile(file: File, id: number): Observable<any> {
    console.log("enter in upload file");
    let url = this.baseUrl + "tbPrayerBox/v1/uploadImage/" + id;

    const formdata: FormData = new FormData();

    formdata.append("file", file);

    return this.http.post(url, formdata);
  }
  getData(page: number) {
    const url = `${this.baseUrl}v1/datahistory?page=${page}`;
    return this.http.get<TbPrayerBox[]>(url, {
      headers: this.headersToken,
    });
  }
  getMainTotalRecords(): Observable<number> {
    const url = this.baseUrl + "v1/get-main-total-records";
    return this.http.get<number>(url, { headers: this.headersToken });
  }
  getTauData(page: number) {
    let url = `${this.baseUrl}v1/gettaubdata?page=${page}`;
    return this.http.get<TbPrayerBox[]>(url, { headers: this.headersToken });
  }
  getTotalRecords(): Observable<number> {
    const url = this.baseUrl + "v1/get-total-records";
    return this.http.get<number>(url, { headers: this.headersToken });
  }
  approveCelb(tbPrayerBox: TbPrayerBox): Observable<any> {
    console.log("enter in save form");
    let url = this.baseUrl + "approveCelb";
    return this.http.post(url, tbPrayerBox);
  }
  approveTau(id: any): Observable<any> {
    console.log("enter in save form");
    let url = this.baseUrl + `v1/content/approve?id=${id}`;
    return this.http.post(url, {}, { headers: this.headersToken });
  }
  reject(id): Observable<any> {
    console.log("enter in save form");
    let url = this.baseUrl + `v1/content/reject?id=${id}`;
    return this.http.post(url, {}, { headers: this.headersToken });
  }
  bulkApprove(selectedItems: TbPrayerBox[]): Observable<any> {
    console.log("Entering bulkApprove method");
    let url = this.baseUrl + "v1/content/bulk-approve";
    return this.http.post(url, selectedItems, { headers: this.headersToken });
  }
  bulkReject(selectedItems: TbPrayerBox[]): Observable<any> {
    console.log("Entering bulkApprove method");
    let url = this.baseUrl + "v1/content/bulk-reject";
    return this.http.post(url, selectedItems, { headers: this.headersToken });
  }
}
