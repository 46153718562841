import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UserDetail } from "../classes/user-detail";
import { UserDetailService } from "../services/user-detail.service";
import { CelebrityService } from "../services/celebrity.service";
import { Celebrity } from "../classes/celebrity";
import { TbPrayerBox } from "../classes/tb-prayer-box";
import { TbPrayerBoxService } from "../services/tb-prayer-box.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  selectedFiles: File;
  currentFileUpload: File;
  registerForm: FormGroup;

  submitted = false;
  loading = false;

  statusarr: any = ["Approve", "Testing"];
  private tbPrayerBox = new TbPrayerBox();
  private celebrity = new Celebrity();
  result: Celebrity[];
  cellist: string[];
  celebCode: any = "";
  zipFile: File;
  xlsxFile: string | Blob;
  userId: any;

  // Define zipFileValue and xlsxFileValue properties
  zipFileValue: any;
  xlsxFileValue: any;

  constructor(
    private tbPrayerBoxService: TbPrayerBoxService,
    private formBuilder: FormBuilder,
    private celebrityService: CelebrityService,
    private toastr: ToastrService
  ) {
    let userdetail = JSON.parse(localStorage.getItem("userDetail"));
    this.userId = userdetail.id;

    this.registerForm = this.formBuilder.group({
      //  message : new FormControl('' , Validators.required),
      status: new FormControl("", Validators.required),
      celbcode: new FormControl("", Validators.required),
      scheduledate: new FormControl("", Validators.required),
      xlsxFile: new FormControl(),
      zipFile: new FormControl(),
    });
  }

  ngOnInit() {
    this.celebrityService.getData().subscribe((data: any) => {
      this.result = data.celebritylist;
      console.log(this.result);
    });
  }

  selectZipFile(event) {
    const file = event.target.files[0];
    console.log(file);
    if (!file) {
      this.toastr.error("Invalid File");
      return;
    }
    if (
      file.type == "application/x-zip-compressed" ||
      file.type == "application/zip"
    ) {
      var size = event.target.files[0].size;
      var kbsize = size / 1024;
      if (kbsize > 1024000) {
        this.toastr.error("size must not exceeds 1 GB");
        this.registerForm.get("zipFile").setValue("");
      } else {
        this.zipFile = event.target.files.item(0);
      }
    } else {
      this.toastr.error("invalid format!");
      //this.registerForm.get('profileImage').setValue("");
    }
  }

  uploadSubmit(form: any) {
    this.loading = true;
    let formdata = new FormData();
    formdata.append("xlsxfile", this.xlsxFile);
    formdata.append("zip", this.zipFile);
    formdata.append("userId", this.userId);
    // formdata.append('token', localStorage.getItem('token'));
    let value = this.celebCode;
    formdata.append("celebcode", value);
    formdata.append("Authorization", localStorage.getItem("token"));
    console.log("Form Data: ", formdata);
    this.celebrityService.uploadZipandxls(formdata).subscribe((data: any) => {
      if (data) {
        this.loading = false;
        if (data.statusDescription.statuscode == 200) {
          form.resetForm();
          this.registerForm.get("xlsxFile").setValue("");
          this.registerForm.get("zipFile").setValue("");
          this.registerForm.get("celebcode").setValue("");
          this.toastr.success(data.statusDescription.statusdescription);
          console.log(data);
        } else {
          this.toastr.error(data.statusDescription.statusdescription);
        }
      }
    });
  }

  selectFile(event) {
    const file = event.target.files[0];
    if (file == undefined) {
      alert("invalid format!");
    }

    if (true) {
      var size = event.target.files[0].size;
      var kbsize = size / 1024;
      if (kbsize > 1048576) {
        alert("size must not exceeds 1 GB");
        this.registerForm.get("xlsxFile").setValue("");
      } else {
        this.xlsxFile = event.target.files[0];
        console.log(event.target.files[0]);
      }
    } else {
      this.toastr.error("invalid format!");
      //this.registerForm.get('profileImage').setValue("");
    }
  }

  // create the form object.

  AdminForm(AdminInformation) {
    this.loading = true;
    if (this.selectedFiles == null) {
      alert("Select file first");
      this.loading = false;
      return;
    }

    this.submitted = true;
    if (this.registerForm.invalid) {
      this.loading = false;
      return;
    }
    // this.tbPrayerBox.message = this.message.value;
    this.tbPrayerBox.status = this.status.value;
    this.tbPrayerBox.celbcode = this.celbcode.value;
    this.tbPrayerBox.scheduleDate = this.scheduledate.value;
    this.tbPrayerBox.conType = "celeb";

    console.log(this.tbPrayerBox);

    this.tbPrayerBoxService.saveData(this.tbPrayerBox).subscribe(
      (data: number) => {
        let result = data;
        console.log(this.result);
        //let result = response.json();
        console.log(result);
        if (result > 0) {
          if (this.selectedFiles != null) {
            // this.currentFileUpload = this.selectedFiles.item(0);
            console.log(this.currentFileUpload);

            this.tbPrayerBoxService
              .uploadFile(this.currentFileUpload, result)
              .subscribe(
                (data: string[]) => {
                  console.log("data is......" + data[0]);
                  let re = data;
                  // let re = res.json();
                  if (this.tbPrayerBox.status == 0) {
                    this.tbPrayerBox.id = result;
                    this.tbPrayerBox.profileImage = re[0];
                    this.tbPrayerBoxService
                      .approveCelb(this.tbPrayerBox)
                      .subscribe((data: number) => {
                        let res = data;

                        // this.result.splice(i,1);
                        //alert("Content approval is done");
                      });
                  }
                  if (re[0] != "-5") {
                    alert("file upload successfully ");
                    this.loading = false;
                    this.submitted = false;
                    //  this.registerForm.get('message').setValue("");
                    this.registerForm.reset();
                  } else {
                    alert("error while uploading fie details");
                    this.loading = false;
                  }
                },
                (err) => {
                  alert("error while uploading fie details");
                  this.loading = false;
                }
              );
          }
        }
      },
      (error) => {
        console.log("error while saving data in the DB");
        this.loading = false;
      }
    );
  }

  //  get message(){
  //   return this.registerForm.get('message');
  // }

  get celbcode() {
    return this.registerForm.get("celbcode");
  }
  get scheduledate() {
    return this.registerForm.get("scheduledate");
  }
  get status() {
    return this.registerForm.get("status");
  }
  get f() {
    return this.registerForm.controls;
  }
}

// import { Component, OnInit } from "@angular/core";
// import {
//   FormGroup,
//   FormControl,
//   Validators,
//   FormBuilder,
// } from "@angular/forms";
// import { UserDetail } from "../classes/user-detail";
// import { UserDetailService } from "../services/user-detail.service";
// import { CelebrityService } from "../services/celebrity.service";
// import { Celebrity } from "../classes/celebrity";
// import { TbPrayerBox } from "../classes/tb-prayer-box";
// import { TbPrayerBoxService } from "../services/tb-prayer-box.service";
// import { ToastrService } from "ngx-toastr";
// //import { jsonpFactory } from '@angular/http/jsonpFactory';

// @Component({
//   selector: "app-register",
//   templateUrl: "./register.component.html",
//   styleUrls: ["./register.component.css"],
// })
// export class RegisterComponent implements OnInit {
//   selectedFiles: File;
//   currentFileUpload: File;
//   registerForm: FormGroup;

//   submitted = false;
//   loading = false;

//   statusarr: any = ["Approve", "Testing"];
//   private tbPrayerBox = new TbPrayerBox();
//   private celebrity = new Celebrity();
//   result: Celebrity[];
//   cellist: string[];
//   celebCode: any = "";
//   zipFile: File;
//   xlsxFile: string | Blob;
//   userId: any;

//   constructor(
//     private tbPrayerBoxService: TbPrayerBoxService,
//     private formBuilder: FormBuilder,
//     private celebrityService: CelebrityService,
//     private toastr: ToastrService
//   ) {
//     let userdetail = JSON.parse(localStorage.getItem("userDetail"));
//     this.userId = userdetail.id;

//     this.registerForm = this.formBuilder.group({
//       //  message : new FormControl('' , Validators.required),
//       status: new FormControl("", Validators.required),
//       celbcode: new FormControl("", Validators.required),
//       scheduledate: new FormControl("", Validators.required),
//       xlsxFile: new FormControl(),
//       zipFile: new FormControl(),
//     });
//   }

//   ngOnInit() {
//     this.celebrityService.getData().subscribe((data: any) => {
//       this.result = data.celebritylist;
//       console.log(this.result);
//     });
//   }

//   selectZipFile(event) {
//     const file = event.target.files[0];
//     console.log(file);
//     if (!file) {
//       this.toastr.error("Invalid File");
//       return;
//     }
//     if (
//       file.type == "application/x-zip-compressed" ||
//       file.type == "application/zip"
//     ) {
//       var size = event.target.files[0].size;
//       var kbsize = size / 1024;
//       if (kbsize > 1024000) {
//         this.toastr.error("size must not exceeds 1 GB");
//       } else {
//         this.zipFile = event.target.files.item(0);
//       }
//     } else {
//       this.toastr.error("invalid format!");
//       //this.registerForm.get('profileImage').setValue("");
//     }
//   }

//   uploadSubmit(form: any) {
//     this.loading = true;
//     let formdata = new FormData();
//     formdata.append("xlsxfile", this.xlsxFile);
//     formdata.append("zip", this.zipFile);
//     formdata.append("userId", this.userId);
//     // formdata.append('token', localStorage.getItem('token'));
//     let value = this.celebCode;
//     formdata.append("celebcode", value);
//     formdata.append("Authorization", localStorage.getItem("token"));
//     console.log("Form Data: ", formdata);
//     this.celebrityService.uploadZipandxls(formdata).subscribe((data: any) => {
//       if (data) {
//         this.loading = false;
//         if (data.statusDescription.statuscode == 200) {
//           form.resetForm();
//           this.zipFile = null;
//           this.xlsxFile = null;
//           this.toastr.success(data.statusDescription.statusdescription);
//           console.log(data);
//         } else {
//           this.toastr.error(data.statusDescription.statusdescription);
//         }
//       }
//     });
//   }

//   selectFile(event) {
//     const file = event.target.files[0];
//     if (file == undefined) {
//       alert("invalid format!");
//     }

//     if (true) {
//       var size = event.target.files[0].size;
//       var kbsize = size / 1024;
//       if (kbsize > 1048576) {
//         alert("size must not exceeds 1 GB");
//         this.registerForm.get("xlsxFile").setValue("");
//       } else {
//         this.xlsxFile = event.target.files[0];
//         console.log(event.target.files[0]);
//       }
//     } else {
//       this.toastr.error("invalid format!");
//       //this.registerForm.get('profileImage').setValue("");
//     }
//   }

//   // create the form object.

//   AdminForm(AdminInformation) {
//     this.loading = true;
//     if (this.selectedFiles == null) {
//       alert("Select file first");
//       this.loading = false;
//       return;
//     }

//     this.submitted = true;
//     if (this.registerForm.invalid) {
//       this.loading = false;
//       return;
//     }
//     // this.tbPrayerBox.message = this.message.value;
//     this.tbPrayerBox.status = this.status.value;
//     this.tbPrayerBox.celbcode = this.celbcode.value;
//     this.tbPrayerBox.scheduleDate = this.scheduledate.value;
//     this.tbPrayerBox.conType = "celeb";

//     console.log(this.tbPrayerBox);

//     this.tbPrayerBoxService.saveData(this.tbPrayerBox).subscribe(
//       (data: number) => {
//         let result = data;
//         console.log(this.result);
//         //let result = response.json();
//         console.log(result);
//         if (result > 0) {
//           if (this.selectedFiles != null) {
//             // this.currentFileUpload = this.selectedFiles.item(0);
//             console.log(this.currentFileUpload);

//             this.tbPrayerBoxService
//               .uploadFile(this.currentFileUpload, result)
//               .subscribe(
//                 (data: string[]) => {
//                   console.log("data is......" + data[0]);
//                   let re = data;
//                   // let re = res.json();
//                   if (this.tbPrayerBox.status == 0) {
//                     this.tbPrayerBox.id = result;
//                     this.tbPrayerBox.profileImage = re[0];
//                     this.tbPrayerBoxService
//                       .approveCelb(this.tbPrayerBox)
//                       .subscribe((data: number) => {
//                         let res = data;

//                         // this.result.splice(i,1);
//                         //alert("Content approval is done");
//                       });
//                   }
//                   if (re[0] != "-5") {
//                     alert("file upload successfully ");
//                     this.loading = false;
//                     this.submitted = false;
//                     //  this.registerForm.get('message').setValue("");
//                     this.registerForm.reset();
//                   } else {
//                     alert("error while uploading fie details");
//                     this.loading = false;
//                   }
//                 },
//                 (err) => {
//                   alert("error while uploading fie details");
//                   this.loading = false;
//                 }
//               );
//           }
//         }
//       },
//       (error) => {
//         console.log("error while saving data in the DB");
//         this.loading = false;
//       }
//     );
//   }

//   //  get message(){
//   //   return this.registerForm.get('message');
//   // }

//   get celbcode() {
//     return this.registerForm.get("celbcode");
//   }
//   get scheduledate() {
//     return this.registerForm.get("scheduledate");
//   }
//   get status() {
//     return this.registerForm.get("status");
//   }
//   get f() {
//     return this.registerForm.controls;
//   }
// }
