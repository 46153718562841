import { Component, OnInit } from "@angular/core";
import { TemplateRef, ViewChild } from "@angular/core";
import { MainTbPrayerBoxService } from "../services/main-tb-prayer-box.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MainTbPrayerBox } from "../classes/main-tb-prayer-box";

@Component({
  selector: "app-tablelist",
  templateUrl: "./tablelist.component.html",
  styleUrls: ["./tablelist.component.css"],
})
export class TablelistComponent implements OnInit {
  loading = true;

  isopen: boolean;
  private mainTbPrayerBox = new MainTbPrayerBox();
  result: MainTbPrayerBox[];
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  editedMessage: any;
  editingId: any;
  modalDialog: any;
  constructor(
    private mainTbPrayerBoxService: MainTbPrayerBoxService,
    public matDialog: MatDialog
  ) {
    this.isopen = false;
  }

  ngOnInit() {
    this.getContentData(this.currentPage);
  }

  getContentData(page: number): void {
    this.loading = true;
    this.mainTbPrayerBoxService.getData(page).subscribe((data: any) => {
      console.log("data: ", data);
      console.log("Here: ", data.maintbPrayerBoxs);
      this.result = data.maintbPrayerBoxs;
      this.mainTbPrayerBoxService
        .getMainTotalRecords()
        .subscribe((totalRecords: number) => {
          console.log(totalRecords);
          this.totalRecords = totalRecords;
          this.loading = false;
        });
      this.loading = false;
    });
  }

  // modalDialog: any;
  // message: string;
  // id: number;
  // openModal(callAPIDialog, message, id) {
  //   this.isopen = true;
  //   this.message = message;
  //   this.id = id;
  //   const dialogConfig = new MatDialogConfig();
  //   console.log("enter in open dialog");
  //   // The user can't close the dialog by clicking outside its body
  //   dialogConfig.disableClose = true;
  //   dialogConfig.id = "modal-component";
  //   dialogConfig.height = "350px";
  //   dialogConfig.width = "600px";
  //   // https://material.angular.io/components/dialog/overview
  //   this.modalDialog = this.matDialog.open(callAPIDialog, dialogConfig);
  // }

  // onSaveClick(): void {
  //   const index = this.result.findIndex(item => item.id === this.editingId);
  //   if (index !== -1) {
  //     this.result[index].message = this.editedMessage;
  //   }
  //   this.onNoClick();
  // }

  openModal(callAPIDialog, message, id) {
    this.isopen = true;
    this.editedMessage = message;
    this.editingId = id;
    const dialogConfig = new MatDialogConfig();
    console.log("enter in open dialog");
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "350px";
    dialogConfig.width = "600px";
    this.modalDialog = this.matDialog.open(callAPIDialog, dialogConfig);
  }

  // onSaveClick(): void {
  //   const index = this.result.findIndex((item) => item.id === this.editingId);
  //   if (index !== -1) {
  //     this.result[index].message = this.editedMessage;
  //   }
  //   this.onNoClick();
  // }

  onSaveClick(): void {
    const index = this.result.findIndex((item) => item.id === this.editingId);
    if (index !== -1) {
      this.result[index].message = this.editedMessage;
      console.log(this.editedMessage, " ", this.editingId);
      this.mainTbPrayerBoxService
        .updateMessage(this.editingId, this.editedMessage)
        .subscribe(
          (response) => {
            console.log("Message updated successfully:", response);
          },
          (error) => {
            console.error("Error updating message:", error);
          }
        );
    }
    this.onNoClick();
  }

  onNoClick(): void {
    this.modalDialog.close();
  }

  // getPageNumbers(): number[] {
  //   const totalPages = this.totalPages();
  //   const pageNumbers = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     pageNumbers.push(i);
  //   }
  //   return pageNumbers;
  // }

  getPageNumbers(): number[] {
    const totalPages = this.totalPages();
    const visiblePages = 5;
    const pageNumbers = [];
    let startPage = Math.max(
      1,
      this.currentPage - Math.floor(visiblePages / 2)
    );
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);
    startPage = Math.max(1, endPage - visiblePages + 1);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  goToPage(page: number): void {
    this.currentPage = page;
    this.getContentData(page);
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getContentData(this.currentPage);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages()) {
      this.currentPage++;
      this.getContentData(this.currentPage);
    }
  }
  totalPages(): number {
    return Math.ceil(this.totalRecords / this.pageSize);
  }
}
