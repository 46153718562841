<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header-danger">
        <h4 class="card-title">Taushiya List</h4>
      </div>
      <div
        class="card-footer text-muted"
        style="display: flex; align-items: center; justify-content: flex-end"
      >
        <button
          type="button"
          class="btn btn-success"
          (click)="bulkApprove()"
          [disabled]="!areCheckboxesSelected()"
          [matTooltip]="showTooltip('approve')"
          matTooltipPosition="above"
        >
          Bulk Approve
        </button>
        <button
          type="button"
          class="btn btn-danger"
          style="margin-left: 10px"
          (click)="bulkReject()"
          [disabled]="!areCheckboxesSelected()"
          [matTooltip]="showTooltip('reject')"
          matTooltipPosition="above"
        >
          Bulk Reject
        </button>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table"
            [class.spinner]="loading"
            [attr.disabled]="loading"
          >
            <thead class="text-danger">
              <th>
                <mat-checkbox
                  [(ngModel)]="checklistMaster"
                  (change)="toggleAllCheckboxes()"
                ></mat-checkbox>
              </th>
              <th>#ID</th>
              <th>CelebName</th>
              <th>ScheduleDate</th>
              <th>Message</th>
              <th>FilePath</th>
              <th>AudioFile</th>
              <th>Action</th>
            </thead>
            <tbody *ngIf="result.length != 0">
              <tr *ngFor="let item of result; let i = index">
                <td>
                  <mat-checkbox
                    [(ngModel)]="selectedCheckboxes[item.id]"
                    (change)="toggleCheckbox(item.id)"
                  ></mat-checkbox>
                </td>
                <th>{{ item.id }}</th>
                <td>{{ item.msisdn }}</td>
                <td>{{ item.createTS | date : "yyyy-MM-dd" }}</td>
                <td>{{ item.message }}</td>
                <td>
                  {{ getModifiedFilename(item.filename) }}
                </td>
                <td>
                  <!-- <button
                    mat-fab
                    style="color: #f26d21"
                    (click)="
                      openModal(callAPIDialog, item.profileImage, item.filename)
                    "
                  >
                    <mat-icon>play_arrow</mat-icon>
                  </button> -->
                  <audio
                    controls
                    style="
                      display: flex;
                      align-self: center;
                      justify-content: center;
                    "
                  >
                    <source
                      [src]="generateAudioSource(item.filename)"
                      type="audio/mp3"
                    />
                    <source
                      [src]="generateAudioSource(item.filename)"
                      type="audio/wav"
                    />
                    Your browser does not support the audio element.
                  </audio>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-success"
                    (click)="approve(item, i)"
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    (click)="reject(item, i)"
                    style="margin-top: 8px"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            </tbody>
            <div *ngIf="result.length == 0">
              <div class="text-center">No Data Available</div>
            </div>
          </table>
        </div>
        <div
          class="pagination"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
          "
        >
          <button (click)="prevPage()" [disabled]="currentPage === 1">
            &lt;&lt;
          </button>
          <ng-container *ngFor="let pageNum of getPageNumbers()">
            <button
              (click)="goToPage(pageNum)"
              [disabled]="pageNum === currentPage"
            >
              {{ pageNum }}
            </button>
          </ng-container>
          <button
            (click)="nextPage()"
            [disabled]="currentPage === totalPages()"
          >
            &gt;&gt;
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #callAPIDialog>
    <div
      *ngIf="isopen"
      id="modal-content-wrapper"
      style="
        display: flex;
        justify-content: baseline;
        align-items: center;
        flex-direction: column;
        gap: 14px;
      "
    >
      <section id="modal-body">
        <h3></h3>
        <audio controls style="align-self: center">
          <source src="{{ filename }}" type="audio/mp3" />
          <source src="{{ filename }}" type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      </section>
      <footer id="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onNoClick()">
          Go back
        </button>
      </footer>
    </div>
  </ng-template>
</div>
