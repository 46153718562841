import { Injectable } from "@angular/core";
import { UserDetail } from "../classes/user-detail";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UserDetailService {
  headersToken = new HttpHeaders();

  // Base URL
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    if (localStorage.getItem("token") && localStorage.getItem("userDetail")) {
      let headers = new HttpHeaders()
        .set("content-type", "application/json")
        .set("Access-Control-Allow-Origin", "*")
        .set("Authorization", JSON.stringify(localStorage.getItem("token")))
        .set(
          "userId",
          String(JSON.parse(localStorage.getItem("userDetail")).id)
        );
      this.headersToken = headers;
    }
  }

  saveData(userDetail: UserDetail): Observable<any> {
    let url = this.baseUrl + "saveEntry";
    return this.http.post(url, userDetail);
  }

  uploadFile(file: File, id: number): Observable<any> {
    let url = this.baseUrl + "uploadImage/" + id;

    const formdata: FormData = new FormData();

    formdata.append("file", file);

    return this.http.post(url, formdata);
  }

  login(form: any) {
    return this.http.post(`${this.baseUrl}auth/v1/login`, form);
  }

  logout(userId: any) {
    return this.http.get(`${this.baseUrl}auth/v1/logout/${userId}`, {
      headers: this.headersToken,
    });
  }
}
