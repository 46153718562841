import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserDetails } from 'src/app/classes/LoginDetail';
import { LoginserviceService } from 'src/app/services/loginservice.service';
import { UserDetailService } from 'src/app/services/user-detail.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userdetails: UserDetails | any;

  constructor(private userdetail: UserDetailService, private loginService: LoginserviceService, private toastr: ToastrService, private router: Router) {
    this.loginService.checkLoginStatus()
  }
  ngOnInit(): void {
    this.userdetails = new UserDetails();

  }




  loginSubmit(form: any) {
    if (form.invalid) {
      return
    }
    let { userName, password } = form.value
    let formdata = {
      username: userName,
      password
    }
    this.userdetail.login(formdata).subscribe((data: any) => {
      if (data) {
        this.userdetails = data;

        if (this.userdetails.response.statuscode == 200) {
          this.loginService.checkLoginStatus();
          this.toastr.success(this.userdetails.response.statusdescription)
          this.setData(this.userdetails)
        }
        else {
          this.toastr.error(this.userdetails.response.statusdescription)
        }
      }
    })





    console.log(form.value)
  }

  setData(data: any) {
    if (data.loginDetail) {
      localStorage.setItem('token', data.loginDetail.userTokenDetailsList[0].jwtToken)
    }


    this.loginService.onlogin();
    localStorage.setItem('userDetail', JSON.stringify(data.loginDetail));
    this.router.navigate(['/home'])
    console.log(data);
  }




}
